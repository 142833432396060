import { graphql } from 'gatsby'
import Page from '../pages-src/projects'

export default Page

export const query = graphql`
  query ProjectsQuery {
    allMdx(filter: { frontmatter: { type: { eq: "project" } } }) {
      edges {
        node {
          excerpt(pruneLength: 160)
          frontmatter {
            type
            slug
            skillType
            title
            date(formatString: "D MMMM YYYY", locale: "fr-FR")
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 600, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
