/** @jsx jsx */
import * as React from 'react'
import { jsx } from 'theme-ui'
import { ItemsMatrix } from '../../components/ItemsMatrix'
import { SEO } from '../../components/SEO'
import { StylizedTitle } from '../../components/StylizedTitle'
import { ProjectsQuery } from '../../types/gatsby-queries'
import { MainLayout } from '../../layouts/main'
import { PageProps } from '../../utils/types'

const Page = React.memo<PageProps<ProjectsQuery>>(({ data, location }) => {
  const { allMdx } = data
  const { edges } = allMdx

  return (
    <>
      <SEO title="Projets" location={location} />
      <MainLayout>
        <StylizedTitle
          as="h1"
          sx={{
            fontSize: [3, 4],
            mb: 4,
          }}
        >
          Projets
        </StylizedTitle>

        <ItemsMatrix withExcerpt items={edges} />
      </MainLayout>
    </>
  )
})
Page.displayName = 'Page'

export default Page
